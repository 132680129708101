import React, { useEffect, useState } from "react";
import InputRadio from "../../../../components/InputRadio";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { createOptions } from "../../../../helpers/selects";

const MultiplePercentage = ({param, productParams, setProductParams}) => {
    const [index, setIndex] = useState(null);

    useEffect(() => {
        let toIndex = getIndexFromObjectArray(productParams, 'id', param.id);
        setIndex(toIndex);
    }, [productParams]);

    useEffect(() => {
        console.log('mudou param', param.options.length)
    }, [param.options])

    const handleParamChange = value => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].value = value;
            return toProductParams;
        });
    }

    return (
        <>
            {index !== null &&
                <>
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>{param.name}</h2>
                        </div>
                    </div>
                    <div className="section">
                        <InputRadio 
                            options={createOptions(param.options, 'description', 'value')}
                            value={productParams[index].value}
                            change={handleParamChange}
                        />
                    </div>
                </>
            }
        </>
    );
}

export default MultiplePercentage;