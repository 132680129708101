import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputFile from "../../components/InputFile";
import api from "../../services/api";
import { ModalDelete } from "../../components/ModalDelete";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { createOptions } from "../../helpers/selects";
import InputEditor from "../../components/InputEditor";
import InputTextarea from "../../components/InputTextarea";
import { renderError } from "../../helpers/errors";
import { cropFileName, splitArray } from "../../helpers/conversions";
import { convertToSlug } from "../../helpers/masks";
import { hasPermission } from "../../services/auth";

const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
];

const featuredOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
];

export const CategoryList = () => {
    const [filters, setFilters] = useState('');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [createCategory, setCreateCategory] = useState(false);
    const [editCategory, setEditCategory] = useState(false);

    const [categoryToDelete, setCategoryToDelete] = useState(null);

    const [categoryId, setCategoryId] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [categorySlug, setCategorySlug] = useState('');
    const [categoryIconBase64, setCategoryIconBase64] = useState('');
    const [categoryIconName, setCategoryIconName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [categoryMetaTitle, setCategoryMetaTitle] = useState('');
    const [categoryMetaDescription, setCategoryMetaDescription] = useState('');
    const [categoryCategoryId, setCategoryCategoryId] = useState(null);
    const [categoryStatus, setCategoryStatus] = useState(true);
    const [categoryFeatured, setCategoryFeatured] = useState(false);

    const [oldSlugs, setOldSlugs] = useState([]);

    useEffect(() => {
        getCategories();
    }, [filters]);

    useEffect(() => {
        if(!createCategory) clean();
    }, [createCategory]);

    useEffect(() => {
        if(!editCategory) clean();
    }, [editCategory]);
    
    useEffect(() => {
        if(createCategory){
            setCategorySlug(convertToSlug(categoryName));
            setCategoryMetaTitle(categoryName);
        }
    }, [categoryName]);

    useEffect(() => {
        if(createCategory){
            setCategoryMetaDescription(categoryDescription);
        }
    }, [categoryDescription]);

    const getCategories = () => {
        setCategories([]);
        setLoading(true);

        api.get(`/category?${filters}`).then(res => {
            setCategories(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const deleteCategory = () => {
        setLoading(true);
        
        api.delete(`/category/${categoryToDelete}`).then(res => {
            setCategoryToDelete(null);
            getCategories();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const storeCategory = () => {
        setLoading(true);

        api.post(`/category`, {
            category_id: categoryCategoryId,
            slug: categorySlug,
            name: categoryName,
            description: categoryDescription,
            meta_title: categoryMetaTitle,
            meta_description: categoryMetaDescription,
            icon: categoryIconBase64,
            status: categoryStatus,
            featured: categoryFeatured
        }).then(res => {
            toast.success('Categoria cadastrada com sucesso');
            setCreateCategory(false);
            getCategories();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateCategory = () => {
        setLoading(true);

        api.put(`/category/${categoryId}`, {
            category_id: categoryCategoryId,
            slug: categorySlug,
            name: categoryName,
            description: categoryDescription,
            meta_title: categoryMetaTitle,
            meta_description: categoryMetaDescription,
            icon: categoryIconBase64,
            status: categoryStatus,
            featured: categoryFeatured
        }).then(res => {
            toast.success('Categoria atualizada com sucesso');
            setEditCategory(false);
            getCategories();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const edit = category => {
        console.log(category.status);
        setOldSlugs(category.old_slugs);
        setCategoryStatus(category.status);
        setCategoryId(category.id);
        setCategoryFeatured(category.featured);
        setCategoryName(category.name);
        setCategorySlug(category.slug);
        setCategoryIconBase64('');
        setCategoryIconName(category.icon ?? '');
        setCategoryDescription(category.description);
        setCategoryMetaTitle(category.meta_title);
        setCategoryMetaDescription(category.meta_description);
        setCategoryCategoryId(category.category_id);
        setEditCategory(true);
    }

    const clean = () => {
        setOldSlugs([]);
        setCategoryStatus(true);
        setCategoryId(null);
        setCategoryCategoryId(null);
        setCategorySlug('');
        setCategoryName('');
        setCategoryDescription('');
        setCategoryMetaTitle('');
        setCategoryMetaDescription('');
        setCategoryIconName('');
        setCategoryIconBase64('');
        setCategoryFeatured(false);
    }

    const Category = ({category}) => {
        const [active, setActive] = useState(false);
    
        return (
            <li className={active ? 'active' : ''}>
                <div className="category">
                    <button onClick={() => setActive(prev => !prev)}>
                        {category?.image_url?.small &&
                            <img src={category?.image_url?.small} />
                        }
                        <span>{category.name} <span className="products-count">{category.products_count} produtos</span></span>
                    </button>
                    {/* <button><img src={ require(`../../assets/images/svgs/plus-circle.svg`).default} /></button> */}
                    {hasPermission('edit_category') &&
                        <button onClick={() => edit(category)}><img src={ require(`../../assets/images/svgs/edit.svg`).default} /></button>
                    }
                    {hasPermission('delete_category') &&
                        <button onClick={() => setCategoryToDelete(category.id)}><img src={ require(`../../assets/images/svgs/delete-small.svg`).default} /></button>
                    }
                    <button onClick={() => setActive(prev => !prev)}><img className="arrow" src={ require(`../../assets/images/svgs/arrow-gray.svg`).default} /></button>
                </div>
                {category.categories &&
                    <ul>
                        {category.categories.map((category, index) => (
                            <SubCategory key={index} category={category} theme={index % 2 === 0 ? 'dark' : 'light'} />
                        ))}
                    </ul>
                }
            </li>
        )
    }
    
    const SubCategory = ({category, theme}) => {
        return (
            <li className={`subcategory ${theme}`}>
                <div className="category-desc">
                    {category?.image_url?.small &&
                        <img src={category?.image_url?.small} />
                    }
                    <span>{category.name}</span>
                </div>
                {hasPermission('edit_category') &&
                    <button onClick={() => edit(category)}><img src={ require(`../../assets/images/svgs/edit.svg`).default} /></button>
                }
                {hasPermission('delete_category') &&
                    <button onClick={() => setCategoryToDelete(category.id)}><img src={ require(`../../assets/images/svgs/delete-small.svg`).default} /></button>
                }
            </li>
        );
    }
    

    return (
        <>
            <ModalDelete 
                show={categoryToDelete !== null}
                action={deleteCategory}
                close={() => setCategoryToDelete(null)}
                loading={loading}
            />
            <Modal
                title={`Cadastrar categoria`}
                size={`big`}
                show={createCategory}
                close={() => setCreateCategory(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCategory}
                        loading={loading} 
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect label={`Status`} value={categoryStatus} change={setCategoryStatus} options={statusOptions} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Destaque`} value={categoryFeatured} change={setCategoryFeatured} options={featuredOptions} />
                        </div>
                    </div>                        
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome *`} value={categoryName} change={setCategoryName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Categoria pai *`} value={categoryCategoryId} change={setCategoryCategoryId} options={createOptions(categories, 'name', 'id', true)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Slug *`} value={categorySlug} change={setCategorySlug} mask={`convertToSlug`} />
                        </div>
                        <div className="col-6">
                            <InputFile 
                                label={`Ícone`} 
                                base64={categoryIconBase64}
                                changeBase64={setCategoryIconBase64}
                                name={categoryIconName}
                                changeName={setCategoryIconName}
                                description={`Tamanho ideal: 20px por 20px`}
                            />
                        </div>
                    </div>
                    <InputTextarea label={`Descrição *`} rows={3} value={categoryDescription} change={setCategoryDescription} />
                </div>
                <div className="section">
                    <Input label={`Meta title *`} value={categoryMetaTitle} change={setCategoryMetaTitle} />
                    <InputTextarea label={`Meta description *`} rows={3} value={categoryMetaDescription} change={setCategoryMetaDescription} />
                </div>
            </Modal>
            <Modal
                title={`Editar categoria`}
                size={`big`}
                show={editCategory}
                close={() => setEditCategory(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Salvar alterações`}
                        svg={`save-white`}
                        action={updateCategory}
                        loading={loading} 
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <InputSelect label={`Status`} value={categoryStatus} change={setCategoryStatus} options={statusOptions} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Destaque`} value={categoryFeatured} change={setCategoryFeatured} options={featuredOptions} />
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome *`} value={categoryName} change={setCategoryName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Categoria pai *`} value={categoryCategoryId} change={setCategoryCategoryId} options={createOptions(categories, 'name', 'id', true, categoryId)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Slug *`} value={categorySlug} change={setCategorySlug} mask={`convertToSlug`} />
                            <div style={{ marginBottom: 30}}>
                                <p style={{ fontSize: 12, fontWeight: 'bold'}}>Histórico</p>
                                {oldSlugs.length === 0 &&
                                    <p style={{ fontSize: 12 }}>Nenhuma slug antiga nesse produto</p>
                                }
                                {oldSlugs.map(oldSlug => (
                                    <p style={{ fontSize: 12 }}>{oldSlug.slug}</p>
                                ))}
                            </div>
                        </div>
                        <div className="col-6">
                            <InputFile 
                                label={`Ícone`} 
                                base64={categoryIconBase64}
                                changeBase64={setCategoryIconBase64}
                                name={categoryIconName}
                                changeName={setCategoryIconName}
                                description={`Tamanho ideal: 40px por 40px`}
                            />
                        </div>
                    </div>
                    <InputTextarea label={`Descrição *`} rows={3} value={categoryDescription} change={setCategoryDescription} />
                </div>
                <div className="section">
                    <Input label={`Meta title *`} value={categoryMetaTitle} change={setCategoryMetaTitle} />
                    <InputTextarea label={`Meta description *`} rows={3} value={categoryMetaDescription} change={setCategoryMetaDescription} />
                </div>
            </Modal>
            <PanelTemplate id="category-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: '[name]', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            {hasPermission('create_category') &&
                                <Button type={`primary`} size={`small`} text={`Cadastrar categoria`} svg="plus-white" action={() => setCreateCategory(true)} />
                            }
                        </>
                    }
                />
                <Loading show={loading} />
                <div className="row">
                    <div className="col-4">
                        <div className="categories">
                            <ul>
                                {!loading && splitArray(categories, 3, 0).map((category, index) => (
                                    <Category key={index} category={category} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="categories">
                            <ul>
                                {!loading && splitArray(categories, 3, 1).map((category, index) => (
                                    <Category key={index} category={category} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="categories">
                            <ul>
                                {!loading && splitArray(categories, 3, 2).map((category, index) => (
                                    <Category key={index} category={category} />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            
            </PanelTemplate>
        </>
    );
}

export default CategoryList;